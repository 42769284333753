<template>
  <!-- 学习资料 -->
  <div class="main-contain">
    <div class="contain">
      <el-table
        ref="singleTable"
        :data="tableData"
        highlight-current-row
        style="width: 100%"
        class="Table"
      >
        <el-table-column label="文件名">
          <template slot-scope="scope">
            <div class="item-left">
              <el-image class="imageicon" :src="file_icon(scope.row.ext)" fit="contain" />
              <div class="title">{{ scope.row.name }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="大小">
          <template slot-scope="scope">
            <div class="item-middle">
              <div class="title">{{ scope.row.size }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <div class="item-right">
              <div class="bt" @click="lookbt(scope.row)">查看</div>
            </div>
            <div class="item-right">
              <div class="bt" @click="download" v-if="scope.row.is_private==0">下载</div>
            </div>
          </template>
          <!-- <template slot-scope="scope">
            <div class="">
              <div class="bt" @click="download"></div>
            </div>
          </template>-->
        </el-table-column>
      </el-table>
    </div>
    <Customdialog
      ref="customdialog"
      class="customdialog"
      width="800px"
      type="form"
      title="预览"
      :showclose="true"
    >
      <div class="dialogbody" slot="dialogbody">
        <template v-if="type == '视频'">
          <video
            class="video_view"
            :src="videoviewurl"
            controls
            controlslist="nodownload"
            width="100%"
            height="500"
            @contextmenu.prevent="handleRMouse"
          />
        </template>
        <template v-if="type == '图片'">
          <el-image class="image_view" :src="imageviewurl" fit="contain" />
        </template>
        <template v-if="type == '其他'">
          <div class="tip_con">
            <div class="icon">!</div>
            <div class="tip_tex">
              <p v-show="is_private==0">该文件暂时不支持在线预览，您可以下载后查看！</p>
              <p v-show="is_private!=0">该文件暂时不支持在线预览！</p>
            </div>
          </div>
        </template>
        <template v-if="type == 'PDF'">
          <div class="pdf_con">
            <md-contract
              :url="fileurl"
              :visible="contractVisable"
              :showBtns="true"
              @handleModal="close"
            ></md-contract>
          </div>
        </template>
      </div>
      <div slot="dialogfooter" class="dialogfooter">
        <el-button type="primary" @click="download" v-if="type != '视频'">下载</el-button>
        <el-button type="info" @click="close_down">关闭</el-button>
      </div>
    </Customdialog>
  </div>
</template>
<script>
import mdContract from "@/contract.md.vue"
import Customdialog from "@/components/customdialog.vue"
import { getVRAttachments } from "@/api/vr"
export default {
  name: "virtualexperiment",
  components: {
    Customdialog,
    mdContract,
  },
  data () {
    return {
      contractVisable: false,
      fileurl: "",
      filename: "",
      fileid: "",
      type: "",
      videoviewurl: "",
      imageviewurl: "",
      testimage: require("@/assets/test.png"),
      icon1: require("@/assets/coursedetails/文件类型-标准图-视频文件.png"),
      icon2: require("@/assets/coursedetails/矢量智能对象 (1).png"),
      tableData: [],
      is_private: "",
      fileicon1: require("@/assets/fileicon/files.png"),
      fileicon2: require("@/assets/fileicon/icon-PDF.png"),
      fileicon3: require("@/assets/fileicon/icon-word.png"),
      fileicon4: require("@/assets/fileicon/icon-video.png"),
      fileicon5: require("@/assets/fileicon/icon-img.png"),
      fileicon6: require("@/assets/fileicon/icon-node.png"),
      fileicon7: require("@/assets/fileicon/icon-audio.png"),
      fileicon8: require("@/assets/fileicon/icon-chapter.png"),
      fileicon9: require("@/assets/fileicon/rar.png"),
      fileicon10: require("@/assets/fileicon/single.png"),
      fileicon11: require("@/assets/fileicon/txt.png"),
      fileicon12: require("@/assets/fileicon/xlsx.png"),
      fileicon13: require("@/assets/fileicon/zip.png"),
    }
  },
  methods: {
    handleRMouse (e) {
      e.preventDefault()
    },
    initdata (data) {
      this.getVRAttachments({
        vr_id: data.id,
        classify: 1,
      })
    },
    file_icon (val) {
      switch (val) {
        case "file":
          return this.fileicon1

        case "pdf":
          return this.fileicon2

        case ("word", "docx"):
          return this.fileicon3

        case "mp4":
          return this.fileicon4

        case ("png", "jpg"):
          return this.fileicon5

        case "radio":
          return this.fileicon7

        case "rar":
          return this.fileicon9

        case "txt":
          return this.fileicon11

        case "xlsx":
          return this.fileicon12

        case "zip":
          return this.fileicon13

        default:
          return this.fileicon10
      }
    },
    lookbt (item) {
      this.is_private = item.is_private
      if (item.ext == 'mp4' || item.ext == 'avi' || item.ext == 'flv') {
        this.type = "视频"
        this.videoviewurl = item.url
      } else if (item.ext == "png" || item.ext == "jpg") {
        this.type = "图片"
        this.imageviewurl = item.url
      } else if (item.ext == "pdf") {
        this.type = "PDF"
      } else {
        this.type = "其他"
      }

      this.fileurl = item.url
      this.filename = item.name
      this.fileid = item.id
      this.$refs.customdialog.dialogopenbt()
      this.contractVisable = true
    },
    close () {
      this.contractVisable = false
      this.contractVisable = false
    },

    // 下载
    download () {
      const a = document.createElement("a")
      a.style.display = "none"
      a.href = this.fileurl
      a.setAttribute("download", this.filename)
      a.setAttribute("target", "_blank")
      document.body.appendChild(a)
      a.click()
      a.remove()
    },
    close_down () {
      this.$refs.customdialog.dialogclosebt()
    },

    getVRAttachments (params) {
      getVRAttachments(params)
        .then((response) => {
          //console.log(response);
          this.tableData = response.data.data
        })
        .catch((error) => {
          //console.log(error);
        })
    },
  },
};
</script>
<style lang="scss" scoped>
.main-contain {
  margin-top: 10px;
  position: relative;

  .contain {
    position: absolute;
    width: 100%;
    height: 100%;
    .Table {
      width: 100%;
      .item-left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .imageicon {
          min-width: 25px;
          height: 25px;
        }
        .title {
          display: inline-block;
          margin-left: 4px;
          font-size: 15px;
          font-weight: 400;
          width: 200px;
          color: #222222;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
        }
      }
      .item-middle {
        .title {
          font-size: 15px;

          font-weight: 400;
          color: #222222;
        }
      }

      .item-right {
        margin-right: 10px;
        .bt {
          font-size: 15px;
          font-weight: 400;
          color: #3d84ff;
          user-select: none;
          cursor: pointer;
        }
      }
    }
  }
  .customdialog {
    .dialogbody {
      padding: 10px;
      .video_view {
      }
      .image_view {
        height: 600px;
        width: 100%;
      }
      .pdf_con {
        position: relative;
      }
      .tip_con {
        height: 60px;
        background: #e6f7ff;
        border: 1px solid #91d5ff;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        .icon {
          margin-right: 10px;
          width: 20px;
          height: 20px;
          line-height: 17px;
          text-align: center;
          border-radius: 50%;
          background: #1890ff;
          color: white;
        }
        .tip_tex {
          font-size: 20px;
          font-weight: bold;
          color: #999999;
        }
      }
    }
    .dialogfooter {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  ::v-deep .cell {
    display: flex;
  }
}
</style>
