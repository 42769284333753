<template>
  <!-- 实验资源 -->
  <div class="main-contain">
    <div class="titlecontian">
      <!-- <el-image class="elimage" :src="icon1" /> -->
      <div class="text1">关联课程:</div>
      <div class="text2" v-for="(item, index) in course_list" :key="index">
        <small @click="getCourse(item)">{{ item.name }}</small>
      </div>
    </div>
    <div class="line"></div>
    <div class="itemcontain" v-for="(item, index) in resource" :key="index">
      <el-image class="elimage" :src="item.cover" />
      <!-- <el-image class="elimage" :src="randomimage()" /> -->
      <div class="rightcontain">
        <div class="introtext">{{ item.name }}</div>
        <div class="introicon">
          <el-image class="elimage" :src="icon2" />
          <div class="text">参与人数:</div>
          <div class="text">{{ item.join_count }}</div>
        </div>
        <div class="enterbt">
          <div class="bt" @click="tourl(item)">进入实验</div>
        </div>
      </div>
    </div>
    <template>
      <div class="dialogs">
        <el-dialog
          :visible.sync="dialogVisible"
          title="原虚拟仿真实验平台账号"
          width="400px"
          top="25%"
          :before-close="handleClose"
        >
          <div class="username_title">
            <span style="display: block; margin-bottom: 10px">账号：</span>
            <el-input v-model="username" placeholder="请输入账号"></el-input>
          </div>
          <div class="username_title">
            <span style="display: block; margin-bottom: 10px">密码：</span>
            <el-input v-model="password" placeholder="请输入密码" show-password clearable></el-input>
          </div>
          <el-button type="primary" @click="oldLogin">登录</el-button>
          <!-- <el-button type="primary">主要按钮</el-button> -->
          <!-- <el-input v-model="username"></el-input>
          <el-input v-model="password"></el-input>-->
        </el-dialog>

        <!-- <el-button type="text" @click="open">登录</el-button> -->
        <el-dialog
          :visible.sync="dialogVisibleCourse"
          title="课程大纲"
          width="800px"
          top="10%"
          :before-close="handleCloseCourse"
        >
          <div class="containCourse">
            <div class="item" v-for="(item0, index) in courseinfo" :key="index">
              <div class="item-title">
                <div class="item-title-text">{{ item0.number }}</div>
                <div class="item-title-text">{{ item0.name }}</div>
              </div>
              <template v-if="item0.children && item0.children.length > 0">
                <div class="item-item" v-for="(item1, index) in item0.children" :key="index">
                  <div class="item-item-icon"></div>
                  <div class="item-item-num">{{ item1.number }}</div>
                  <div class="item-item-text">{{ item1.name }}</div>
                </div>
              </template>
            </div>
          </div>
          <!-- <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="toCourseDetails(courseinfo)">查看课程详情</el-button>
          </div>-->
        </el-dialog>
      </div>
    </template>
  </div>
</template>
<script>
import {
  getVRModuleList,
  getvrmodulelink,
  oldPlatformUser,
  getVRInfo,
} from "@/api/vr"
import Cookies from 'js-cookie'
import { getToken } from "@/utils/auth"
import { getoutlinelist } from "@/api/outline"
import axios from "axios"

const BaseReqUrl = require("@/utils/base_url.js")

export default {
  name: "Courseintroduction",
  components: {},
  data () {
    return {
      testimage: require("@/assets/test.png"),
      icon1: require("@/assets/coursedetails/矢量智能对象 (1).png"),
      icon2: require("@/assets/new/people.png"),
      course_list: [],
      resource: "",
      data_interface_status: "",
      username: "",
      password: "",
      module_id: "",
      dialogVisible: false,
      image01: require("@/assets/defaultimages/1首页--1实验用房使用排行_23.jpg"),
      image02: require("@/assets/defaultimages/1首页--1实验用房使用排行_25.jpg"),
      image03: require("@/assets/defaultimages/1首页--1实验用房使用排行_36.jpg"),
      image04: require("@/assets/defaultimages/1首页--1实验用房使用排行_60.jpg"),
      image05: require("@/assets/defaultimages/1首页--1实验用房使用排行_57.jpg"),
      image06: require("@/assets/defaultimages/1首页--1实验用房使用排行_41.jpg"),
      image07: require("@/assets/defaultimages/1首页--1实验用房使用排行_44.jpg"),
      image08: require("@/assets/defaultimages/1首页--1实验用房使用排行_03.jpg"),
      dialogVisibleCourse: false,
      courseinfo: "",
    }
  },
  created () {
    this.getresourceinfo({ vr_id: this.vr_id })
    this.getvrinfo({ id: this.vr_id })
  },
  inject: ["vr_id"],
  methods: {
    randomimage () {
      let items = [
        this.image01,
        this.image02,
        this.image03,
        this.image04,
        this.image05,
        this.image06,
        this.image07,
        this.image08,
      ]
      let item = items[Math.floor(Math.random() * items.length)]
      return item
    },
    getvrinfo (params) {
      // let params = { vr_id: this.vr_id }
      getVRInfo(params).then((response) => {
        this.data_interface_status = response.data.data_interface_status
        this.username = response.data.username
        this.password = response.data.password
      })
    },
    //调用莱医特接口
    oldLogin () {
      let timer = new Date().getTime()
      var par = {
        LogUser: this.username, //取值get-info
        LogPwd: this.password, //取值get-info
      }
      var data_math = JSON.stringify(par)
      // let url = "http://xnfz.hzau.edu.cn/api/User/Login/?t=";
      let url = ''
      if (process.env.NODE_ENV == "development") {
        url = BaseReqUrl.dev_url + "xnfz-login?t="
      } else if (process.env.NODE_ENV == "production") {
        url = BaseReqUrl.pro_url + "xnfz-login?t="
      }

      axios({
        url: url + timer,
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
        changeOrigin: true,
        data: data_math,
      }).then((response) => {
        //成功调用接口
        if (response.data.success == true && response.data.Token) {
          this.old_token = response.data.Token
          let params = {
            username: this.username,
            password: this.password,
            guid: response.data.GUID,
          }
          //调用保存老平台虚拟仿真用户信息
          oldPlatformUser(params).then((response) => {
            if (response.code === 0) {
              this.dialogVisible = false
              let module_id = localStorage.getItem("module_id")
              let vr_ilabx_token_id = localStorage.getItem("vr_ilabx_token_id")
              let params = {
                id: module_id,
                vr_ilabx_token_id: vr_ilabx_token_id,
              }
              getvrmodulelink(params).then((response) => {
                if (response.data) {
                  window.open(response.data.url, "_blank")
                }
              })
            }
          })
        } else {
          this.$message({
            message: "账号或密码错误，请重新登录",
            type: "warning",
          })
          this.dialogVisible = true
        }
      })
    },
    handleClose () {
      this.username = ""
      this.password = ""
      this.dialogVisible = false
    },
    tourl (item) {
      // this.show_input = true;
      let vr_ilabx_token_id = localStorage.getItem("vr_ilabx_token_id")
      const token = getToken() ? true : false;
      if (token == true || vr_ilabx_token_id != 'undefined') {
        if (this.data_interface_status == 2) {
          let redirectType = Cookies.get("redirectType")
          if (redirectType === '1' || (this.username && this.password)) {
            this.dialogVisible = false
            //this.oldLogin();
            localStorage.setItem("module_id", item.id)
            let params = { id: item.id, vr_ilabx_token_id: vr_ilabx_token_id }
            getvrmodulelink(params).then((response) => {
              if (response.data) {
                window.open(response.data.url, "_blank")
              }
            })
          } else {
            this.dialogVisible = true
          }
        } else {
          let params = { id: item.id, vr_ilabx_token_id: vr_ilabx_token_id }
          getvrmodulelink(params).then((response) => {
            if (response.data) {
              window.open(response.data.url, "_blank")
            }
          })
        }
      } else {
        let params = { id: item.id }
        getvrmodulelink(params).then((response) => {
          if (response.data) {
            window.open(response.data.url, "_blank")
          }
        })
        // this.$confirm("当前未登陆账号，成绩不会记录，是否继续", "提示", {
        //   confirmButtonText: "继续",
        //   cancelButtonText: "取消",
        //   type: "warning",
        // })
        //   .then(() => {

        //     // this.$router.push({
        //     //   path: "/login",
        //     // });
        //   })
        //   .catch(() => {});
      }
    },
    initdata (data) {
      // console.log("===>关联课程",data.course_list)
      this.course_list = data.course_list
    },
    // 打开课程章节弹框
    getCourse (item) {
      this.dialogVisibleCourse = true
      let params = {
        course_sn: item.course_sn,
      }
      this.getoutlinelist(params)
    },
    getresourceinfo (params) {
      getVRModuleList(params)
        .then((response) => {
          // console.log("实验资源列表:", response);
          if (response.data && response.data.length > 0) {
            this.resource = response.data
          }
        })
        .catch((error) => {
          //console.log(error);
        })
    },
    // 获取课程章节内容
    getoutlinelist (params) {
      getoutlinelist(params)
        .then((response) => {
          this.courseinfo = response.data
          this.courseinfo.course_sn = params.course_sn
        })
        .catch((error) => {
          //console.log(error);
        })
    },
    // 关闭弹框
    handleCloseCourse () {
      this.dialogVisibleCourse = false
    },
    // 转到课程详情
    // toCourseDetails(item) {
    //   this.dialogVisibleCourse = false;
    //   // console.log(item,"item");
    //   this.$router.push({
    //     path: "/home/wisdompracticeteaching/coursedetails",
    //     query: {
    //       course_sn: item.course_sn,
    //     },
    //   });
    // }
  },
};
</script>
<style lang="scss" scoped>
.main-contain {
  padding: 10px;
  background: #ffffff;

  .titlecontian {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .elimage {
      margin-right: 30px;
      width: 18px;
      height: 15px;
    }

    .text1 {
      font-size: 16px;

      font-weight: bold;
      color: #000000;
    }

    .text2 {
      margin-left: 10px;
      font-size: 18px;
      font-weight: bold;
      color: #3d84ff;
      margin-top: -3px;
      cursor: pointer;
    }
  }

  .line {
    margin-top: 10px;
    //   margin-bottom: 10px;
    border-bottom: 1px solid #cccccc;
  }

  .itemcontain {
    //    border: 1px solid red;
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .elimage {
      margin-right: 30px;
      width: 240px;
      height: 140px;
      border-radius: 5px;
    }

    .rightcontain {
      //  border: 1px solid red;
      flex: 1;

      .introtext {
        font-size: 16px;

        font-weight: 500;
        color: #000000;
      }

      .introicon {
        margin-top: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .elimage {
          margin-right: 20px;
          width: 18px;
          height: 19px;
        }

        .text {
          margin-right: 10px;
          font-size: 15px;

          font-weight: 400;
          color: #666666;
        }
      }

      .enterbt {
        // border: 1px solid red;
        margin-top: 60px;
        margin-right: 20px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .bt {
          width: 100px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          background: #3d84ff;
          border-radius: 4px;
          font-size: 16px;
          font-weight: bold;
          color: #ffffff;
          user-select: none;
          cursor: pointer;
        }
      }
    }
  }

  .username_title {
    margin-bottom: 20px;
  }

  .containCourse {
    height: 600px;
    overflow-y: auto;
    margin-right: -15px;

    .item {
      margin-top: 20px;

      .item-title {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .item-title-text {
          margin-right: 30px;
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #666666;
        }
      }

      .item-item {
        margin-top: 20px;
        margin-left: 70px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .item-item-icon {
          width: 12px;
          height: 12px;
          background: #c6c6d3;
          border-radius: 50%;
        }

        .item-item-num {
          margin-right: 10px;
          margin-left: 10px;
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #666666;
        }

        .item-item-text {
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #666666;
        }
      }
    }
  }
}
</style>
